import * as React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/Layout";
import wellspringPhoto from "../images/landing_image.jpg";
import { Standout } from "../components/Standout";
import {
  faCross,
  faChurch,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { useCalendarEvents } from "../utils/useCalendarEvents";
import { formatEventDate } from "../utils/calendar-utils";
import fallFestivalImage from "../images/fall-festival-2024-social.png";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="The website of Wellspring Bible Church of Alliance, Ohio"
        />
        <meta
          name="google-site-verification"
          content="TtgOILXn-esMrKIbElXrGEIcEXFIALNK1M-YAkLYXjM"
        />
      </Helmet>
      <main className="relative bg-slate-100 z-0">
        <img
          src={wellspringPhoto}
          alt=""
          className="w-full h-[36rem] md:h-[48rem] object-cover z-0 absolute"
        />
        <div className="w-full h-[36rem] md:h-[48rem] z-10 bg-gray-500 absolute mix-blend-multiply opacity-60" />
        <div className="w-full h-[36rem] md:h-[48rem] z-20 relative">
          <div className="absolute w-full mt-12 md:mt-20 pb-12 flex flex-col items-center justify-start text-left">
            <div className="my-8 px-6 mb-48 pb-32">
              <div className="w-full flex items-center">
                <h1 className="text-white text-5xl md:text-7xl text-center font-serif">
                  <span className="leading-tight text-left">
                    Real people doing real life together on mission with Jesus
                  </span>
                </h1>
              </div>
              <div className="flex justify-center mt-24 sm:mt-48 lg:mt-8">
                <a
                  href="#worship"
                  className="rounded-full bg-white hover:bg-cyan-900 text-cyan-900 hover:text-white shadow-gray-800 shadow-sm hover:shadow-cyan-900 text-sm sm:text-lg px-8 py-3"
                >
                  Join us for worship
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center bg-orange-950 py-16">
          <div className="grid grid-cols-1 grid-flow-row w-full justify-items-center space-y-12 text-white">
            <img
              src={fallFestivalImage}
              className="object-contain max-w-sm sm:max-w-xl md:max-w-2xl rounded-2xl shadow-xl"
            />
            <div className="px-4">
              <h4 className="text-xl sm:text-3xl font-bold">
                Join us for a time of free family fun!
              </h4>
              <div>
                <ul className="list-disc ml-4 mt-2">
                  <li>Caricatures</li>
                  <li>Face painting</li>
                  <li>Pumpkin painting</li>
                  <li>Straw bale climbing</li>
                  <li>Bounce house</li>
                  <li>Outdoor games</li>
                  <li>Food</li>
                  <li>And more</li>
                </ul>
              </div>
              <div className="mt-2">
                We'll also have a prayer table open if you would like prayer.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center bg-white py-16">
          <h2 className="text-3xl md:text-4xl text-center text-cyan-900 mb-2 font-serif">
            Who We Are
          </h2>
          <h3 className="text-lg text-cyan-900 text-center mb-8 self-center w-full px-8">
            Wellspring Bible Church is a non-denominational church in Alliance,
            Ohio.
          </h3>
          <div className="grid grid-flow-row grid-cols-1 gap-8 md:grid-cols-3 px-8">
            <Standout
              title="Encounter Jesus"
              icon={faCross}
              content="We ﬁnd true intimacy in a relationship with our Savior, the Lord Jesus Christ."
            />
            <Standout
              title="Embrace Community"
              icon={faChurch}
              content="We embrace the community of believers, Christ's church, and walk through life together with oneness in the Lord."
            />
            <Standout
              title="Engage Culture"
              icon={faEarthAmericas}
              content="We seek to interact and engage with the culture in which we live, leading people into a life changing relationship with Jesus."
            />
          </div>
          <div className="text-cyan-600 text-xl text-center mt-16">
            Interested in learning more about Wellspring Bible Church?
          </div>
          <div className="flex flex-row justify-center gap-4">
            <div className="flex justify-center mt-4 md:mt-8">
              <a
                href="/about/our-beliefs"
                className="rounded-full  bg-white hover:bg-gray-200 text-cyan-700 border-2 border-solid border-cyan-700 text-sm sm:text-lg px-8 py-3"
              >
                Our Beliefs
              </a>
            </div>
            <div className="flex justify-center mt-4 md:mt-8">
              <a
                href="/about/our-mission"
                className="rounded-full  bg-cyan-700 hover:bg-cyan-800 text-white text-sm sm:text-lg px-8 py-3"
              >
                Our Mission
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col py-16 px-8">
          <h2 className="text-4xl text-center text-cyan-900 font-serif">
            Upcoming Events
          </h2>
          <a
            href="/events"
            className="text-sky-700 text-lg text-center mb-8 mt-1"
          >
            View all events
          </a>
          <EventsTable />
        </div>
        <div
          className="flex flex-col items-center self-center px-6 pt-16 pb-16 bg-cyan-950"
          id="worship"
        >
          <h2 className="text-white text-4xl text-center mb-8 font-serif">
            Come Worship With Us!
          </h2>
          <div className="text-white text-lg text-center">
            <span className="font-bold">Worship service:</span>{" "}
            <span className="font-light">10:30am every Sunday</span>
          </div>
          <div className="text-white text-lg text-center">
            <span className="font-bold">ABF (Bible Study):</span>{" "}
            <span className="font-light">9:30am every Sunday</span>
          </div>
          <div className="text-white text-lg text-center">
            <a
              className="text-blue-400 visited:text-purple-400"
              href="https://maps.app.goo.gl/VskRN7sz92ycgCZg7"
              target="_blank"
              rel="noreferrer"
            >
              304 North Rockhill Ave, Alliance, OH 44601
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

function EventsTable() {
  const { loading, errorMessage, upcomingEvents, cancelledEvents } =
    useCalendarEvents({ maxResults: 100 }); // for some reason 4 doesn't work

  if (loading) {
    return (
      <div className="text-lg text-slate-600 text-center">
        Looking for events, please wait...
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="text-lg text-red-800 text-center">
        There was a problem loading events, please check back again later!
      </div>
    );
  }

  if (upcomingEvents.length === 0) {
    return (
      <>
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center">
            <div className="text-2xl text-cyan-900 font-bold mb-4">
              No upcoming events
            </div>
            <div className="text-lg text-cyan-900 text-center">
              Check back soon for upcoming events!
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {upcomingEvents.slice(0, 3).map((event) => {
        const { cancelled, formattedDate } = formatEventDate(
          event,
          cancelledEvents
        );
        return (
          <div className="flex flex-col border-2 border-gray-300 rounded-xl p-4">
            <h4 className="text-xl font-bold text-sky-600">{event.summary}</h4>
            <div className="text-lg text-slate-500">{formattedDate}</div>
            {cancelled && (
              <div className="text-red-500 font-semibold">
                Note: Upcoming event has been cancelled. Check back next time!
              </div>
            )}
            <div className="mt-auto pt-4">
              <a
                href={`/events?id=${event.id}`}
                className="inline-block rounded-full  bg-cyan-700 hover:bg-cyan-800 text-white text-sm sm:text-lg px-8 py-3"
              >
                More Info
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default IndexPage;
